<template>
    <div class="body">
        <div class="home-class">
            <!-- <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="关键词">
                    <el-input v-model="formInline.number" placeholder="编号、巡视类型"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form> -->
            <div class="hand-class">
                <img src="../../assets/images/yunwei/add.png" @click="editClick(1)" alt="">
                <img src="../../assets/images/yunwei/edit.png" @click="editClick(0)" alt="">
                <img src="../../assets/images/yunwei/delete.png" @click="deleteClick" alt="">
                <img src="../../assets/images/yunwei/export.png" @click="exportClick" alt="">
            </div>
            <div class="header-input">
            <!-- 表格 -->
                <el-table
                    :data="tableData"
                    highlight-current-row
                    style="width: 100%"
                    class="hi-table"
                    height="75vh"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>
                    <el-table-column
                    label="编号"
                    align="center"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.pollingNumber }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="巡查类型" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.pollingType }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding fixed-width"
                    >
                        <template slot-scope="scope">
                            <el-button type="text" @click="detailClick(scope.row.id)">巡检项目</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[2, 5, 10, 15]"
                    :page-size="size"
                    class="pagination-style"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <div v-if="addDialogVisible == true">
            <add-class-dialog
            :addDialogVisible = "addDialogVisible"
            :isAdd="isAdd"
            :queryOneId="queryOneId"
            @closeAddDialog="closeAddDialog"
            ></add-class-dialog>
        </div>
    </div>
</template>

<script>
import addClassDialog from './dialog/addClassDialog.vue';
export default {
    components: {
        addClassDialog
    },
  mounted() {
    this.getListData()
  },
  data() {
    return {
        formInline: {},
        tableData: [],
        powerStationName: localStorage.getItem("powerStationName"),
        total: 0,
        size: 10,
        currentPage: 1,
        addDialogVisible: false, //是否显示新增/编辑弹框
        isAdd: 1, // 0编辑 1新增
        isBan: true,
        queryOneId: null, //单条数据id
        selectList: [],
    };
  },
  methods: {
    handleSizeChange(val){
        this.size = val
        this.getListData()
    },
    handleCurrentChange(val){
        this.currentPage = val
        this.getListData()
    },
    // 新增/编辑
    editClick(key){
        if(key===0&&this.isBan) return
        this.isAdd = key
        this.addDialogVisible = true
    },
    // 关闭弹框
    closeAddDialog(){
        this.addDialogVisible = false
        this.getListData()
    },
    // 查询
    onSubmit(){
        // this.getListData()
    },
    // 巡检项目
    detailClick(id){
        this.$router.push({ path: '/yunwei/yunwei/inspectionClassList', query: {id} })
    },

    //表格勾选
    handleSelectionChange(val){
        this.selectList = []
        val.forEach((item)=>{
            this.selectList.push(item.id)
        })
        if(val.length != 1){
            this.isBan = true
        }else{
            this.isBan = false
            this.queryOneId = val[0].id
            console.log('表格勾选',this.queryOneId,val)
        }
    },
    async getListData(){
        let params = {
            "pageSize": this.size,
            "currPage": this.currentPage,
            'powerStationName': this.powerStationName
        }
        params.powerStationCode = localStorage.getItem("powerStationCode");
        let res=await this.$http.post('polling/list',params)
        if(res.data.code==200){
            this.tableData = res.data.data.data
            this.total = res.data.data.count
        }else{
            this.$message.error(res.data.message)
        }
    },
    // 导出
    exportClick(){
        this.$http.post("polling/Exp").then(res => {
            if (res.data.code == 200) {
                console.log('res.data.data',res.data.data.data)
                window.open(res.data.data.data);
            }
        });


        // let res=await this.$http.post('polling/Exp')
        // if(res.data.code==200){
        //     that.$notify.success({
        //     title: "提示",
        //     message: "导出成功",
        //     showClose: true,
        //     });
        // }else{
        //     this.$message.error(res.data.message)
        // }
    },
    //删除
    async deleteClick(){
        if(this.selectList.length<1) return
        let delList = this.selectList
        let res=await this.$http.post('polling/deleteBatch',[...delList])
        if(res.data.code==200){
            this.$message.success('删除成功')
            this.getListData()
        }else{
            this.$message.error(res.data.message)
        }
    },
  }
}
</script>

<style scoped lang="scss">
/*body及表格样式 */
.body{
    background: #223f6c;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    .home-class{
        height: 100%;
        padding: 2px 2px 2px 2px;
        background-color: #2d5981;
        box-sizing: border-box;
        padding: 8px;
        .hand-class{
            display: flex;
            text-align: right;
            justify-content: flex-end;
            margin: 10px 0;
            img{
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                margin: 0 5px;
            }
        }
        .hi-table{
            // height: 50vh;
        }
    }
}
/deep/ .el-form-item__label{
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #ffffff;
        text-align: left;
        line-height: normal;
        font-size: 21px;
}
/deep/ .el-input__inner{

  border-radius: 5px;
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}
/deep/ .el-table--border, .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
}
    /deep/ .el-table--border th{
        background-color: #21527e;

     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }

    /deep/ .el-table th{
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
    /deep/ .el-table td{
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
  /deep/ .el-table__body-wrapper{
    background: #2d5981;
  }
  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #223f6c !important;
}
  /deep/ .el-pagination{
    display: flex;
    justify-content: flex-end;
  }
    /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #f2f2f2;
    background-color: #00b2e6;
    cursor: default;
    }
    /deep/ .el-pager li{
        background: none;
        color: #f2f2f2;
    }
    /deep/ .el-icon{
        color: #fff;
    }

    /deep/ .el-pagination .btn-prev{
        background: none;
    }
    /deep/ .el-pagination .btn-next{
        background: none;
    }
  /deep/ .el-pagination__total{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
 /deep/ .el-pagination__jump{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }

  /*body及表格样式结束 */

  ::v-deep .el-checkbox {
  margin-right: 0;
}
</style>
