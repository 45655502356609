<template>
  <div>
    <el-dialog
      :title="isAdd ? '新增' : '修改'"
      :visible.sync="addDialogVisible"
      @close="close('form')"
    >
      <el-form
        label-width="100px"
        :model="itemForm"
        :rules="rules"
        class="form-class"
        ref="form"
      >
        <el-form-item label="电站名称：" prop="powerStationName">
          <el-input
            v-model="itemForm.powerStationName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="pollingNumber">
          <el-input type="number" v-model="itemForm.pollingNumber"></el-input>
        </el-form-item>
        <el-form-item label="巡检类型" prop="pollingType">
          <el-input v-model="itemForm.pollingType"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">提交</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    // 0编辑 1新增
    isAdd: {
      typeof: Number,
      default: null
    },
    queryOneId: {
      typeof: Number,
      default: null
    }
  },
  mounted() {
    // 获取详情
    if (this.isAdd === 0 && this.queryOneId) {
      this.getDetail();
    }
  },
  data() {
    return {
      addDialogVisible: true,
      itemForm: {},
      rules: {
        powerStationName: [{ required: true, message: "电站名称不能为空" }],
        pollingNumber: [{ required: true, message: "编号不能为空" }],
        pollingType: [
          { required: true, message: "请输入巡视类型", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    close(formName) {
      this.addDialogVisible = false;
      this.resetForm(formName);
      this.$emit("closeAddDialog");
    },
    async submit() {
      let that = this;
      let res = await this.$http.post("polling/save", this.itemForm);
      if (res.data.code == 200) {
        if (this.isAdd) {
          that.$notify.success({
            title: "提示",
            message: "添加成功",
            showClose: true
          });
        } else {
          that.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true
          });
        }
        this.close();
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getDetail() {
      let res = await this.$http.post("polling/queryOne", {
        id: this.queryOneId
      });
      if (res.data.code == 200) {
        this.itemForm = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-class {
  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 150px;
  }
  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
  }
}
/deep/ .el-icon-close:before {
  color: #333333;
}
</style>
